
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { ProductDetail } from '@/types/product.d'
@Component({
  name: 'AgentList'
})
@KeepAlive
export default class AgentList extends Vue {
  private loading = false
  private info: Array<ProductDetail> = []

  get parentProductId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.product.selectNewProductByPage, {
      parentProductId: this.parentProductId
    }).then((res) => {
      this.info = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productChildAdd',
      params: {
        parentId: this.parentProductId
      }
    })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'productChildDetail',
      params: { id }
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该产品吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteNewProduct, {
        productId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
